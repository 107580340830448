.archk-call-controls {
    padding-left: $spacer;
    padding-right: $spacer;

}

.archk-call-button {

    padding: 10px;
    // background: $green;

    border-left: solid 1px $white;

    &:hover {
        background: $info;
        cursor: pointer;

        -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
        box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);

    }

    &.archk-call-button-active {
        background: $warning !important;
    }
}

.archk-on-call {

    // position: fixed;
    // bottom: 0;
    // right: $spacer;
    // z-index: 999999;
    // background: white;
    // border-radius: $border-radius-sm;
    // width: 350px;

    // &.archk-on-call-minimized {
    //     width: 225px;
    // }

    // .archk-on-call-wrapper {
    //     padding: $spacer $spacer *2;
    // }

    // i {
    //     cursor: pointer;
    //     z-index: 10;
    // }


 

    // .archk-call-buttons {

    //     overflow: hidden;

    //     .col {
    //         padding: $spacer * 1 $spacer *2;
    //         cursor: pointer;
            
    //         &:hover {
    //             background: $gray-300;
    //         }
    //     }

    //     .archk-call-button {
            // &:hover {
            //     background-color: $red !important;
            // }
    //     }
    // }

    // .display-4 {
    //     font-size: 20px;
    // }
    


}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(170, 228, 23, 0.7);
      }
      
      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(170, 228, 23, 0);
      }
      
      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(170, 228, 23, 0);
      }
}

.name-circle.Answered {
    animation: pulse-red 2s infinite;
    box-shadow: 0 0 0 0 rgba(170, 228, 23, 1);
}