.badge {

   border-radius: $border-radius-sm;
   padding-left: $spacer;
   padding-right: $spacer;
   font-size: $font-size-sm;

   color: $white;

}
