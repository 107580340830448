.card {
  
    border: solid 1px $gray-300;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    box-shadow: none;

    .card-header, .card-body, .card-footer {
        padding-top: $spacer * .5;
        padding-bottom: $spacer * .5;
    }

    &.card-no-shadow {
        box-shadow: none;
    }

    &.card-hover {
        cursor: pointer;
        transition: all .2s;
        
        background: $white;

        .card-header, .card-body, .card-footer {
            background: $white;
            transition: all .2s;
        }

        &:hover {
            background: $success;

            .card-header, .card-body, .card-footer {
                background: $success;
                color: $white;
            }
        }
    }

    &.card-aggregation {
        a {
            display: block;
            &:hover {
                background-color: $gray-200;
            }
        }

        .card-body {
            padding-left: 0;
            padding-right: 0;
        }

        .row {
            margin-left: 0;
            margin-right: 0;
        }

        .col-6, .col-lg-2 {
            padding-left: 0;
            padding-right: 0;
        }

    }

    .card-title {
        font-weight: bold;
    }

}