.form-control:disabled {
    background-color: $gray-300 !important;
}

select {
    -webkit-appearance: none !important;
}
.form-control:disabled,
.form-control[readonly] {
    background-color: $input-bg;
    opacity: 0.7;
}
.form-control:focus {
    color: #8898aa;
    background-color: #fff;
    border-color: #06405C;
    outline: 0;
    // box-shadow: $box-shadow-sm;
}
.rdtPicker .rdtTimeToggle:before {

    content: "Time Select";
}

.form-group {

    .form-control-label {
        position: relative;
        left: 0;
        transition: all .3s;
    }

    &:focus-within {
        .form-control-label {
            color: $gray-900; 
            position: relative;
        }
    }
}


.react-tel-input {

    .flag-dropdown {
        border-radius: 0;
        border: solid 1px $gray-300;
        border-radius: solid 1px red !important;
    }

    .form-control {
        width: 100%;
        border-radius: 0;
        font-size: $font-size-sm;
        height: auto;
        padding-top: .375rem;
        padding-bottom: .375rem;
        line-height: 1.5;
        border: solid 1px $gray-300 !important;

        &:active {
            color: #212529;
            background: #f6f9fc;
            border: solid 1px #212529;
            font-weight: bold;
            border-radius: 0;
            box-shadow: inherit;
        }

      
        // line-height: inherit;
        // letter-spacing: inherit;
        // background: inherit;
        // border: inherit;
        // font-size: inherit;
    }
}


.form-control:invalid:focus, .form-control.is-invalid:focus, .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: $gray-900;
}

.form-control {
    background: $white;
    padding-left: $spacer;
    padding-right: $spacer;
    padding-top: $spacer * .75;
    padding-bottom: $spacer * .75;
    transition: all .3s;
    height: auto;

    &:focus {
        color: $gray-900;
        background: $gray-100;
        border: solid 1px $gray-900;
        font-weight: bold;
        border-radius: 0;

        &::placeholder {
            color: lighten($gray-900, 20%);
        }
    }
}

.form-group {
    margin-bottom: $spacer;
}

input[type='checkbox'], input[type='radio'] { filter: grayscale(.8) }

.table {
    .react-datepicker-wrapper {
        display: inline;
    }
}

.react-datepicker-wrapper {
    display: block;

    .react-datepicker__input-container {
        position: relative;
        display: inline-block;
        width: 100%;

        border-radius: 0;
        box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);

        input {
            width: 100%;
            border: 1px solid #dee2e6;
            background: $white;
            padding-left: $spacer;
            padding-right: $spacer;
            padding-top: $spacer * .60;
            padding-bottom: $spacer * .60;
            transition: all .3s;
            height: auto;
            font-size: $font-size-sm;
            color: $gray-600;

            // &:active {
            //     background: $input-bg;
            // }
            &:focus {
                color: $gray-900;
                background: $gray-100;
                border: solid 1px $gray-900;
                font-weight: bold;
                border-radius: 0;
        
                &::placeholder {
                    color: lighten($gray-900, 20%);
                }
            }
        }

    }

}

.date-picker-wrapper {

    &.invalid {
        input {
            border: solid 1px $red;
        }
    }

    &.valid {
        input {
            border: solid 1px $green;
        }
    }
}

.statements-date-picker {
    input {
        text-align: center;
        max-width: 150px;
        border-radius: 0 !important;
        cursor: pointer;
    }
}

.dataTables_filter input {
    margin-left: $spacer * .5;
}

label {
    margin-bottom: $spacer / 2.5;
}

@media(max-width: 576px) {

    select, input, textarea {
        font-size: 16px !important;
    }

    .form-control  {

        padding: .2rem .7rem !important;
        height: calc(2rem + 2px) !important;

        select, input, textarea {
            font-size: 16px !important;
        }
    }

}

.custom-control-label::before {
    border: 1px solid $blue;
}

.archk-file-upload-item-wrapper {
    // box-shadow: $box-shadow-sm;
    background-color: $white;
}

.dropzone {
    // box-shadow: $box-shadow-sm;
    // border-radius: 4px;
}

.react-select {
    font-size: $font-size-sm;
    color: $body-color;
}

.form-control-invalid {
    display: none;
}
input.is-invalid + .form-control-invalid, select.is-invalid + .form-control-invalid {
    display: inline-block;
    font-size: $font-size-sm;
    color: $warning;
    margin-bottom: 0;
}

.hide-toolbar {
    .ql-toolbar {
        display: none;
    }
}

.quill {
    .ql-container {
        .ql-editor {
            background: $white;
            padding-left: $spacer;
            padding-right: $spacer;
            padding-top: $spacer * .75;
            padding-bottom: $spacer * .75;
            transition: all .3s;
            height: auto;
            color: red;

            p {
                color: $input-color !important;
                margin-bottom: 0;
            }
        
            &:focus {
                color: $gray-900;
                background: $gray-100;
                border: solid 1px $gray-900 !important;
                font-weight: bold;
                border-radius: 0;
        
                &::placeholder {
                    color: lighten($gray-900, 20%);
                }
            }
        }
    }
   
}



.archk-master-search {

    .results-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 900;
        background: rgba(0,0,0,.3)
    }

    .search-icon {
        z-index: 1025;
        color: $body-color !important;
    }

    .search-wrapper {
        input {
            position: relative;
            z-index: 1000;
        }
    }

    .results {
        position: fixed;
        top: 45px;
        width: 600px;
        max-width: 90%;
        left: 213px;
        right: 0;
        max-width: 90%;
        background-color: white;
        border-radius: 0;
        z-index: 1000;
        background: white;
        overflow-x: hidden;
        // padding-left: $spacer;
        // padding-right: $spacer;
        overflow: none;
        overflow-y: visible;
        // i {
        //     width: 20px;
        // }

        .identifier {
            font-size: $font-size-sm * 1.15;
            text-transform: uppercase;
        }

        .results-wrapper {
            min-height:200px;
            height: 400px;
            max-height: 80vh;
            overflow-x: hidden;
            padding-top: $spacer;
            padding-bottom: $spacer;
        }

        .results-section {
            padding-left: $spacer * 2;
            padding-right: $spacer * 2;
        }
      
        h4, li {
            padding-top: $spacer * 1;
    
        }

        // li {
        //     padding-left: $spacer * 2;
        //     padding-right: $spacer * 2;
        //     padding-bottom: $spacer * 1;

        // }

        ul {
            // padding-top: $spacer * 2;
            // padding-bottom: $spacer * 2;
            display: block;
            width: 100%;
            border-radius: $border-radius-sm;
            margin-bottom: $spacer;
        }

        li {

            cursor: pointer;
            border-radius: 0;
            transition: all .2s;
            background: $white;
            // border: solid 1px $gray-300;
            outline: solid 1px $gray-300;
            padding-left: $spacer;

            &:hover {
                background: $info;
                color: $white;

                outline: solid 1px darken($info, 10%);

                .text-info, .text-warning, .text-success, .text-dark, .text-muted {
                    color: $white !important;
                }
                a {
                    color: $white;
                }
            }

            &.hide-hover {
                cursor: default;
                &:hover {
                    background-color: transparent;
                    color: inherit;
                }
            }

            .badge {
                margin-top: 2px;
                margin-bottom: 2px;
            }

            .nav-section {
                display: inline-block;
                width: 105px;
            }
        }

    }

}

.custom-control-label::before {
    top: 1px;
}

.form-group-search {
    position: relative;

    i {
        position: absolute;
        left: $spacer;
        top: $spacer * 1;
        color: $gray-500;
    }

    .form-control {
        padding-left: $spacer * 3.5;
    }

}

.form-control-label-block {
    .form-control-label {
        display: block
    }
}

.custom-control-label::after {
    top: 1px;
}

.custom-radio.checkbox-bg-success .custom-control-input:checked ~ .custom-control-label::before {
    background: $green;
    border-color: $white;
}