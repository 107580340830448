$sidebarWidth: 250px;
$header-height: 85px;

.archk-template-docs {

    .archk-template-docs-main {
        margin-left: $sidebarWidth;
        // position: fixed;
        // left: $sidebarWidth;
        margin-top: $header-height;
        // margin-top: 10px;
        // right: 0;
        // bottom: 20px;
        // overflow: auto;
        padding-top: 5px;
        // border-top: solid 1px #ddd;
    }

    .archk-template-docs-header {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        border-bottom: solid 1px $gray-300;
        background: $white;
        z-index: 3;

        // .archk-template-docs-header-center {
        //     padding-left: $sidebarWidth;
        // }

        input {
            border-radius: 0 !important;
        }

        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .archk-template-docs-sidebar {

        position: fixed;
        left: 0;
        bottom: 0;
        top: $header-height;
        overflow: auto;
        width: $sidebarWidth;
        background: $white;
        border-right: solid 1px $gray-300;

        h3 {
            position: relative;
            top: 1px;
            font-size: 16px !important;
        }

        .sidebar-disabled {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 5;
            background: rgba(255,255,255,.5);
        }

        .text-sm {
            font-size: 13px !important;
        }
        .no-markers {
            font-size: 13px !important;
        }

        .marker-row {
            font-size: 13px !important;

            span {
                font-size: 13px !important;
            }
        }

        .viewing-icon.viewing-icon-contacts {
            &:hover {
                background-color: $info;
            }

        }

        .viewing-icon {
            border: solid 1px $gray-300;
            padding: 4px;
            border-radius: 4px;
            padding-left: 8px;
            padding-bottom: 6px;

            &:hover {
                background-color: $info;
                color: $white !important;
            }

            &.active {
                padding: 6px;
                padding-left: 8px;
                padding-right: 6px;

                &:hover {
                    background-color: $white !important;
                    color: $green !important;
                }
            }

        }


        .section {
            padding: $spacer / 2 1rem;
            h3 {
                margin-bottom: -5px;
            }
        }

        .non-active-wrapper {
            // border-right: solid 6px $gray-600;
            border-left: solid 6px $gray-600;
            // border-top: solid 4px $gray-200;
            // border-bottom: solid 4px $gray-300;
            // margin-bottom: 4px;
        }

        .active-wrapper {
            // border-right: solid 6px $info;
            border-left: solid 6px $info;
            // border-top: solid 6px lighten($dark, 30%);
            // border-bottom: solid 6px lighten($dark, 30%);
            // margin-bottom: 4px;
        }

        // .markup-inner-wrapper {
        //     .active-wrapper {
        //         border-bottom: solid 6px lighten($warning, 30%);
        //         border-top: solid 6px lighten($warning, 30%);
        //         border-left: solid 6px $warning;
        //         border-right: solid 6px $warning;
        //     }
        // }

        .party-white-out {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            // background: rgba(255,255,255,.3);
            // backdrop-filter: blur(.2px);

            // opacity: .5;
            z-index: 3;
            // border-bottom: solid 1px $white;

            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -moz-flex;
            display: -ms-flexbox;
            display: flex;

            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            -moz-justify-content: center;
            justify-content: center;

            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            -moz-align-items: center;
            align-items: center;

            span {
                display: none;
            }

            &:hover {
                background-color: rgba(0,0,0,.7);

                span {
                    display: block;
                }
            }
        }

        ul {

            padding-left: 0;
            margin-bottom: 0;

            ul {
                li {
                    // padding-left: 30px;
                    background-color: $gray-200;
                    border-bottom: solid 1px $white;

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }

            &.hover-pop {

                li {
                    &:hover {
                        cursor: pointer;
                        background-color: $gray-300;
                    }
                }

            }

            li {
                list-style: none;
                padding: $spacer * .5 1rem;
                // background-color: $gray-100;
                border-bottom: solid 1px $gray-400;

                

                &:first-child {
                    border-top: solid 1px $gray-200;
                }

                .icon-identifier {
                    i {
                        width: 20px;
                        height: 20px;
                        font-size: 9px;
                        background: $green;
                        padding: 4px;
                        color: white;
                        border-radius: 3px;
                        border: solid 1px white;
                        box-shadow: 1px 1px 1px 1px black;
                        position: relative;
                        top: -1px;
                        margin-right: $spacer;
                        
                        &.party-0 {
                            background-color: $dark;   
                        }
                        &.party-1 {
                            background: $green;
                        }
                        &.party-2 {
                            background: $tealOriginal;
                        }
                        &.party-3 {
                            background: $red;
                        }
                        &.party-4 {
                            background: $purple;
                        }
        
                    }
                }

                .page-number {
                    float: right;
                    color: white;
                    // width: 40px;
                    font-size: 12px;
                    border-radius: 20px;
                    font-weight: bold;
                    margin-top: 4px;

                    &.party-0 {
                        color: $dark;   
                    }
                    &.party-1 {
                        color: $green;
                    }
                    &.party-2 {
                        color: $tealOriginal;
                    }
                    &.party-3 {
                        color: $red;
                    }
                    &.party-4 {
                        color: $purple;
                    }
                   
                    
                }

            }   

        }

    }

    .pdf-marker {

        position: absolute;
        z-index: 2;
        border: solid 2px darken($yellow, 5%);
        background: rgba(255, 214, 0, .3);;
        width: 100%;

        &.inactive {
            background-color: rgba(222,226,230, .6);
            border: solid 2px darken($gray-300, 15%);
        }

        .marker-name {
            bottom: 2px;
            line-height: 14px;
            position: absolute;
            left: 2px;
            width: 100%;
            font-weight: bold;
            font-size: 12px;
            white-space: break-all;
            max-width: calc(100% - 2px);
            word-break: break-all;
        }

        &.is-markup {
            .marker-name {
                white-space: pre;
            }
        }

        &:hover {
            .toolbar {
                display: block;
            }
        }

        .toolbar {
            display: none;
            height: 30px;

            width: 150px;
            position: absolute;
            top: -31px;
            margin-left: -2px;

            ul {

                li {
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    padding-top: 4px;
                    cursor: pointer;
                    color: $gray-300;

                    &:hover {
                        background: $gray-600
                    }

                    &.active {
                        color: $green;
                    }

                }

            }

        }

        .identifier {

            i {

                position: absolute;
                bottom: -38px;
                left: calc(50% - 20px);
                width: 35px;
                height: 35px;
                background: $green;
                padding: 8px;
                color: white;
                border-radius: 3px;
                border: solid 2px white;
                box-shadow: 2px 2px 2px 2px black;

                &.party-0 {
                    background-color: $dark;   
                }
                &.party-1 {
                    background: $green;
                }
                &.party-2 {
                    background: $tealOriginal;
                }
                &.party-3 {
                    background: $red;
                }
                &.party-4 {
                    background: $purple;
                }

            }

        }

        .text-input {

            display: none;
            height: 30px;
            width: 150px;
            position: absolute;
            top: -78px;
        }


    }

}

// .archk-template-docs-send-modal {
//     align-items: flex-end;

//     .modal-content {
//         max-height: 80vh;
//     }
// }