body .react-bootstrap-table-pagination .dataTables_length select.form-control-sm {
    min-width: 50px;
    width: auto;
}

@media(max-width: 768px) {
    .react-bootstrap-table-pagination-total {
         display: none;
    }

    .dataTables_filter {
        input, select {
            width: 100%;
        }
    }

    .dataTables_length {
        display: none !important
    }

}

.react-bootstrap-table-pagination .react-bootstrap-table-pagination-list .pagination {
    padding-right: $spacer;
}

.react-bootstrap-table {

    

    thead {
        tr {
            background: $gray-200;
            
        }
    }

    th {
        background: $gray-200;
        color: $body-color;
    }

    .sortable {
        cursor: pointer;
    }

    .notification-unread {
        td {
            background-color: $white;
        }
    }

    .notification-read {
        td {
            background-color: $gray-200;
            border-bottom: solid 1px $white;
        }
    }

}

.skeletal-loading-table {

    thead {
        th {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    tbody {

        tr {
            background: white !important;
        }

        @keyframes skeleton-load-bg {
            0% { background: $gray-200; }
            25% { background: $gray-400; }
            50% { background: $gray-200; }
            75% { background: $gray-400; }
            100% { background: $gray-200; }
        }

        .skeleton-load {
            -webkit-animation: skeleton-load-bg 5s infinite; /* Safari 4+ */
            -moz-animation:    skeleton-load-bg 5s infinite; /* Fx 5+ */
            -o-animation:      skeleton-load-bg 5s infinite; /* Opera 12+ */
            animation:         skeleton-load-bg 5s infinite; /* IE 10+, Fx 29+ */
            position: relative;
            display: block;
            height: 20px;
            margin-top: 4px;
            margin-bottom: 4px;
            border-radius: $border-radius;
        }
    }

}

.table-no-stripes {
    .table {
        tbody {
            tr{
    
                &:nth-child(even) {
                    background: $white;
                }
    
            }
        }
    }
   
}

.table {

    .row-mine {
        border-top: solid 1px $gray-400;
        border-bottom: solid 1px $gray-400;
        background-color: $gray-100 !important;
        border-top-left-radius: 20px;
        position: relative;

        td::before {

                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 4px;
                height: 100%;
                background-color: $info;
                border-radius: 40px;

          
        }
        // position: relative;
        // &::before {
        //     content: '';
        //     position: absolute;
        //     left: 42%;
        //     top: 100%;
        //     width: 0;
        //     height: 0;
        //     border-left: 20px solid transparent;
        //     border-right: 20px solid transparent;
        //     border-top: 20px solid #e8e8e8;
        //     clear: both;
        // }
    }

    td {
        vertical-align: middle;
        white-space: normal;
        word-break: break-all;
        padding: .3rem;
        &.td-pre {
            white-space: pre;
        }
    }

    thead {
        tr {
            background: $gray-200;
            th {
                // color: white;
                padding-top: $spacer * .5;
                padding-bottom: $spacer * .5;
                vertical-align: middle;
            }
        }
    }

    tbody {
        tr{

            &:nth-child(even) {
                background: $gray-100;
            }

            td {
                font-size: $font-size-sm;
            }
    
            // &:hover {
            //     background: $gray-200;

            //     td {
            //         position: relative;
            //     }

            //     td:first-child::before {
            //         position: absolute;
            //         top: 0;
            //         left: 0;
            //         bottom: 0;
            //         width: 3px;
            //         color: $gray-700;
            //         background: $gray-700;
            //         content: ' ';
            //     }

            //     td:last-child::before {
            //         position: absolute;
            //         top: 0;
            //         right: 0;
            //         bottom: 0;
            //         width: 3px;
            //         color: $gray-700;
            //         background: $gray-700;
            //         content: ' ';
            //     }

            // }
    
        }
    }

    .aligned-image {
        position: relative;
        margin-top: -0px;

    }

}

.table.table-no-effects {
    tbody {
        tr {
            &:hover {
                background: white;

                td:first-child::before {
                    position: static;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    color: none;
                    background: none;
                    content: ' ';
                }

                td:last-child::before {
                    position: static;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 0;
                    color: none;
                    background: none;
                    content: ' ';
                }


                
            }
        }
    }
}


@if ($theme == dark) {
    .page-item.disabled .page-link {

        background: $gray-900;
        border-color: $blackOffset;
    }
}

.react-bs-table-csv-btn {
    background: $primary !important;
    border-color: $primary !important;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: $teal;
    border-color: $teal;
}

.table-no-margin {
    table.table {
        margin-bottom: 0;
    }
}

.table-responsive {

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .col-md-6:first-child, .col-xs-6:first-child, .col-sm-6:first-child, .col-lg-6:first-child {
        padding-left: 0;;
    }

    .col-md-6:last-child, .col-xs-6:last-child, .col-sm-6:last-child, .col-lg-6:last-child {
        padding-right: 0;;
    }

}

.table-not-fixed {

    table.table {
        table-layout: unset;

        td {
            word-break: break-word;
            white-space: pre;
        }
    }

}

.table-fixed {

    table.table {
        table-layout: fixed !important;
        td {
            word-break:normal;
            white-space:initial;
        }
    }

}

.table-overflow-show {
    // .table {
        overflow: visible;
    // }
}
.table-overflow-hidden {
    .table {
        overflow: hidden;
    }
}
.table-overflow-auto {
    .table {
        overflow: auto;
    }
}

.table-fixed {

    table.table {
        table-layout: fixed;
    }

}

table.table {

    td {
        word-break: break-word;
        // white-space: pre;
    }

    th[data-row-selection="true"] {
        width: 20px !important;
    }

}

.react-bootstrap-table-pagination .dataTables_length select.form-control-sm {
    width: 40px;
    text-align: center;
    margin: auto;
}


.table-parent-bordered {

    .table {

        td {
            border: solid 4px $gray-200;
        }

        .react-json-view {
            font-size: 1.1rem;
        }

    }

}

@media(max-width: 600px) {

    .dataTables_filter {

        input {
            width: calc(100%) !important;
        }

        .text-right {

            text-align: left !important;
            
            button {
                display: block !important;
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                margin-top: $spacer * 1.5;
            }

        }

    }

    td {
        min-width: 150px;
    }

    .table-select {
        td:nth-child(1) {
            min-width: 50px;

        }
    }

}

.modal-table {
    .table {
        th, tr td {
            &:first-child {
                padding-left: $card-spacer-x;
            }
            &:last-child {
                padding-right: $card-spacer-y * 2;
            }
        }
    }
}

.table-no-data {
    input {
        display: none;
    }
}

@media (max-width: 600px) {
    .dataTables_filter input {
        width: calc(100% - 18px) !important;
        max-width: 200px;
    }
}

.table-icons {

    .table-icon-wrapper {
        margin-left: $spacer;
        display: inline-block;

        &:first-child {
            margin-left: 0;
        }

        .table-icon {
            border: solid 1px $gray-400;
            border-radius: 100%;
            width: 30px !important;
            height: 30px !important;
            font-size: $font-size-sm;
            text-align: center;
            display: inline-block;

            
            &.table-icon-disabled {
                cursor: default !important;
                background: $gray-300 !important;
                color: $body-color !important;
                border: solid 1px $gray-500 !important; 
                position: relative;

                &:after {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    content: "\00D7"; /* use the hex value here... */
                    font-size: 53px; 
                    color: $gray-500;
                    line-height: 28px;
                    text-align: center;
                    z-index: 1;
                }
            }
        

            &.table-icon-info {
                background-color: lighten($info, 15);
                border-color: $info;
                color: $white;


                &:hover {
                    cursor: pointer;
                    background: $info
                }
            }

            &.table-icon-success {
                background-color: lighten($success, 15);
                border-color: $success;
                color: $white;

                &:hover {
                    cursor: pointer;
                    background: $success
                }
            }
            &.table-icon-warning {
                background-color: lighten($warning, 15);
                border-color: $warning;
                color: $white;

                &:hover {
                    cursor: pointer;
                    background: $warning
                }
            }
            &.table-icon-danger {
                background-color: lighten($danger, 15);
                border-color: $danger;
                color: $white;

                &:hover {
                    cursor: pointer;
                    background: $danger
                }
            }
            
            i {
                padding-top: 8px;
                position: relative;
                z-index: 2;
            }
        }

    }


}

.table.table-not-bordered tbody tr:nth-child(even) {
    background: $white;
}