.archk-matter-page {

    .avatar-image {
        max-width: 100%;
        border-radius: 100%;
    }

    .sidebar {
        width: 200px;
    }

    .sidebar, .main {
        height: calc(100vh - 90px);
        overflow: auto;
    }

    .main {
        background: $gray-100;

    }

    .sidebar {
        .section {
            // padding-bottom: $spacer * 2;
        }
    }

    .section {
        padding-bottom: $spacer * 1;
        padding-top: $spacer * 1;
        // border-top: solid 1px $gray-200;

        padding-left: $spacer * 1.5;
        padding-right: $spacer * 1.5;

        &:nth-child(odd) {
            // background: white
        }

        &:last-child {
            border-bottom: none;
        }

        border-bottom: solid 1px $gray-300;
    }

    .sidebar-left {
        
        .badge {
            display: inline-block;
            min-width: 65px;
        }
    }
    .sidebar-right {
        .badge {
            display: inline-block;

        }
    }


    .archk-matter-page-header {

        background-color: $gray-900;

        .archk-matter-page-header-top {
            border-bottom: solid 1px $white;
            padding-top: $spacer * 1;
            padding-bottom: $spacer * 1;
        }
        .archk-matter-page-header-bottom {

            padding-top: $spacer * 1.5;
            padding-bottom: $spacer * 1.5;

            // i {
            //     font-size: 20px;
            // }
        }

     

        .avatar-image {
            max-width: 100%;
            border-radius: 100%;
        }
        p {
            color: $white;
            margin-bottom: 0;
        }
    }

    .archk-timeline {

        .card {
            box-shadow: none;
        }

        p {
            font-size: $font-size-base;
            margin-bottom: 0;
        }

        .icon-wrapper {
            position: relative;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            color: $white;

           

            &.icon-wrapper-info {
                border: solid 1px $info;
                background-color: lighten($info, 10%);
            }
            &.icon-wrapper-warning {
                border: solid 1px $warning;
                background-color: lighten($warning, 10%);
            }
            &.icon-wrapper-success {
                border: solid 1px $success;
                background-color: lighten($success, 10%);
            }
            &.icon-wrapper-purple {
                border: solid 1px $purple;
                background-color: lighten($purple, 10%);
            }

            i {
                position: absolute;
                display: inline-block;
                height: 15px;
                width: 15px;
                top: calc(7px);
                left: calc(8px);
            }
        }

        // i {
        //     width: 40px;
        //     height: 40px;
        // }
    }

    .archk-matter-history {
        .stepper-wrapper {
            margin-top: auto;
            display: flex;
            justify-content: space-between;

        }

        .step-name {
            text-align: center;
            max-width: 100px;
            font-size: $font-size-sm;
        }
        .stepper-item {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;
        
            @media (max-width: 768px) {
            font-size: 12px;
            }
        }
        
        .stepper-item::before {
            position: absolute;
            content: "";
            border-bottom: 3px solid $gray-300;
            width: 100%;
            top: 20px;
            left: -50%;
            z-index: 2;
        }
        
        .stepper-item::after {
            position: absolute;
            content: "";
            border-bottom: 3px solid $gray-300;
            width: 100%;
            top: 20px;
            left: 50%;
            z-index: 2;
        }
        
        .stepper-item .step-counter {
            position: relative;
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: $white;
            border: solid 3px $gray-300;
            margin-bottom: 6px;
        }
        
        .stepper-item.active-item {
            font-weight: bold;

            &::after {
                border-bottom: 3px solid $teal;

            }
            .step-counter {
                border: solid 3px $teal;
                background: $white !important;

                
            }
        }
        
        .stepper-item.completed .step-counter {
            border: solid 3px $green;
            background-color: $white;
            font-size: 20px;
        }
        .stepper-item.active-item .step-counter {
            background-color: $teal;
            color: $white;
        }
        
        .stepper-item.completed::after {
            position: absolute;
            content: "";
            border-bottom: 3px solid $green;
            width: 100%;
            top: 20px;
            left: 50%;
            z-index: 3;
            color: $white;

        }
        .stepper-item.completed::before {

            color: $white;

        }
        
        .stepper-item:first-child::before {
            content: none;
        }
        .stepper-item:last-child::after {
            content: none;
        }
    }
}