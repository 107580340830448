.archk-overview {

    .card {
        border-width: 2px !important;
        .card-header {
            height: 35px;
        }
    }

    .border {
        border-width: 2px !important;
    }

    .table-responsive {
        min-height: 175px;
        position: relative;

        .fa-check-circle {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            padding: 30px;
            width: 100%;
            font-size: 100px;
            text-align: center;
        }
        
        .fa-number {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            padding: 30px;
            width: 100%;
            font-size: 100px;
            text-align: center;
        }
        
    }
}