.archk-messaging {

    .archk-messaging-texts-container {
        // display: flex;
        // flex-direction: column-reverse;
    }

    .archk-message {
        clear: both;
    }

    .archk-message-incoming {
        .archk-message-wrapper {
      
            // border-radius: $border-radius;

            background: lighten( $info, 10);
            border: solid 1px $info;
            color: $white;

            .archk-message-title {
                color: $white;
            }
        }
    }
    .archk-message-outgoing {
        .archk-message-wrapper {
            background: $gray-200;
            border: solid 1px $gray-500;
            // // border-radius: $border-radius;
            // float: right;

           
        }
    }

    .archk-message-wrapper {
        // display: inline-block;
        // width: 500px;
        // max-width: 90%;
        padding: $spacer * 1;

        // margin-left: $spacer;
        // margin-right: $spacer;
        // margin-top: $spacer;
        // margin-bottom: $spacer;
    }

    .chat {
        position: fixed;
        left: 204px;
        right: 204px;
        top: 124px;
        bottom: 50px;
        overflow: none;
        background: $gray-100;
        margin: 0 auto;


        padding: 15px 30px;
        overflow-y: scroll;
        transform: rotate(180deg);
        direction: rtl;
        &__wrapper {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
        }
        &__message {
            margin-bottom: $spacer;
          max-width: 600px;
          transform: rotate(180deg);
          direction: ltr;
          &-own {
            color: #fff;
            margin-left: auto;
            background-color: #00a9de;
          }
        }
      }
      
      .chat__form {
        background-color: #e0e0e0;
        position: fixed;
        left: 204px;
        right: 204px;
        bottom: 0;
        overflow: none;


        form {
          max-width: 800px;
          margin: 0 auto;
          height: 50px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
        input {
          height: 40px;
          font-size: 16px;
          min-width: 90%;
          padding-left: 15px;
          background-color: #fff;
          border-radius: 15px;
          border: none;
        }
        button {
          width: 10%;
          height: 100%;
          font-size: 16px;
          background-color: transparent;
          border: none;
          text-align: center;
          text-transform: uppercase;
          cursor: pointer;
          &:hover {
            font-weight: bold;
          }
        }
      }



}