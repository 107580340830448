// .page-messaging {

//     $sidebarWidth: 250px;

//     .sidebar {

//         position: fixed;
//         top: $fixed-header-height + 1;
//         bottom: 0;
//         background: $white;
//         padding: 0;

//         overflow: auto;

//         .section {
//             padding: $spacer 1rem;
//         }

//         .title {
//             margin-bottom: 0;
//         }

//         .showing-container {

//             .showing-action {
//                 cursor: pointer;
//             }

//             .active {
//                 text-decoration: underline;
//                 font-weight: bold;
//                 color: $orange;
//             }

//         }

//         ul {

//             li {

//                 padding: $spacer 1rem;
//                 border-bottom: solid 1px $gray-400;
//                 font-size: $font-size-base * 0.9;
//                 transition: all 0.3s;

//                 &:first-child {
//                     border-top: solid 1px $gray-400;
//                 }

//                 &:hover {
//                     background-color: $gray-300;
//                     box-shadow: $box-shadow-sm;
//                     position: relative;
//                 }

//                 .from {
//                     font-weight: bold;

//                     .date {
//                         font-weight: normal;
//                         font-size: $font-size-base * 0.8;
//                     }

//                 }

//             }

//         }

//         &.sidebar-left {
//             width: $sidebarWidth;
//             border-right: solid 1px $gray-400;
//             left: 0;
//         }

//         &.sidebar-right {
//             width: $sidebarWidth;
//             border-left: solid 1px $gray-400;
//             right: 0;
//         }

//     }


//     .center-content {

//         position: fixed;
//         top: $fixed-header-height;
//         left: $sidebarWidth;
//         right: $sidebarWidth;
//         padding: $spacer;
//         overflow: auto;
//         height: calc(100% - 90px);

//         .card-no-show {
//             box-shadow: none;
//             border: none;
//         }

//         .dz-message {
//             padding: 2.5rem 1rem;
//         }

//         .standard-attachment {
//             background: $gray-200;
//             padding: 3px 8px;
//             margin-bottom: $spacer;
//             cursor: pointer;
//         }

//     }


//     @media(max-width: 820px) {

//         .sidebar {
//             top: $fixed-header-height + 21px;
//         }

//         .center-content {
//             top: $fixed-header-height + 20px;

//             button {
//                 width: 100%;
//                 margin-bottom: $spacer;
//             }
//         }

//     }

//     .attachment {
//         display: inline-block;
//         width: 160px;
//         height: 120px;
//         position: relative;
//         margin-right:  10px;
//         margin-bottom: 10px;

//         &:hover {
//             .attachment-details, .attachment-download {
//                 display: block;
//             }
//         }

//         .attachment-img {
//             position: absolute;
//             top: 0;
//             right: 0;
//             bottom: 0;
//             left: 0;
//             background-size: cover;
//             background-position: center;

//             &.icon-container {

//                 i {
//                     font-size: 4rem;
//                     margin-top: 25px;
//                     color: $orange
//                 }
//             }

//         }

//         .attachment-download {
//             position: absolute;
//             bottom: 0;
//             left: 0;
//             right: 0;
//             background: rgba(0,0,0,.4);
//             padding: 6px 10px;
//             display: none;

//             &:hover {
//                 background: rgba(255,255, 255, 1);
//                 p {
//                     color: black;
//                 }
//             }

//             p {
//                 font-size: .8rem;
//                 color: white;
//                 line-height:16px;
//                 font-weight: bold;
//                 margin-bottom: 0;
//             }

//             span {
//                 float: right;
//             }
//         }

//         .attachment-details {
//             transition: all .5s;
//             display: none;
//             position: absolute;
//             top: 0;
//             right: 0;
//             bottom: 0;
//             left: 0;
//             background: rgba(0,0,0,.6);
//             padding: 10px;

//             p {
//                 font-size: .9rem;
//                 color: white;
//                 line-height:16px;
//                 font-weight: bold;
//                 text-transform: uppercase;
//                 max-height: 80px;
//                 margin-bottom: 0;

//             }

//         }

//     }

//     @media(max-width: 1100px) {
//         .attachment {

//             width: 140px;
//             height: 100px;

//         }

//     }

//     @media(max-width: 1000px) {
//         .attachment {

//             width: 120px;
//             height: 90px;

//         }

//     }

// }


// .archk-bg-on-call {
//     .archk-send-email-modal {

//         right: calc(360px);

//         &:nth-child(2) {
//             right: calc(845px)
//         }

//     }
// }

// .archk-send-email-modal {

//     z-index: 1000;
//     position: fixed;
//     bottom: 0;
//     right: 0;
//     width: auto;
//     z-index: 1000;
//     margin-right: $spacer * 1;



//     .minimized {
//     }

//     &:nth-child(2) {
//         right: calc(485px)
//     }

 
//     // -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
//     // box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);

//     // -webkit-box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
//     // box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);

//     -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
//     box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);


//     .ql-editor {
//         height: 260px !important;
//         max-height: 60vh;
//         background: $white;
//         border: none !important;
//     }

//     .ql-editor:focus {
//         &:focus {
//             border: none !important;
//         }
//     }

// }

// .archk-send-email {
    
//     width: 475px;

//     // &.minimized {
//     //     width: 300px;
//     //     margin-left: -200px;

//     // }


//     .react-select {

//         font-size: $font-size-base * 1;

//         div {
//             color: $gray-700 !important;
//         }

//     }

//     .show-attachments {
//         padding-left: $spacer;
//         padding-right: $spacer;

//         .attachment {
//             background-color: $gray-200;
//             padding: 3px 8px;
//             margin-bottom: 5px;
//             position: relative;

//             .remove {

//                 position: absolute;
//                 right: 10px;
//                 color: $red;
//                 cursor: pointer;
//                 margin-top: 2px;

//             }

//         }

//     }


//     .ql-toolbar {

//         .ql-picker, button, select {
//             position: relative;
//             top: 3px;
//         }

//         .ql-background {
//             top: 1px;
//         }

//         button.send {
//             background: $green;
//             height: auto;
//             color: white;
//             padding: 5px 30px;
//             width: auto;
//             top: 0;
//             margin-left: 8px;

//             &:focus {
//                 color: $white !important;
//             }
//             &:hover {
//                 color: $white !important;
//             }

//         }

//         button.btn.btn-outline-success:focus {
//             color: $green !important;
//         }

//         button.template {
//             background: white;
//             color: $green;
//             border: solid 1px $green;
//             height: auto;

//             padding: 5px 30px;
//             width: auto;
//             top: 0;
//             margin-left: 8px;

//             &:focus {
//                 color: $white !important;
//             }

//         }

//     }

//     .subject-controller {

//         padding-top: $spacer * .5;
//         padding-bottom: $spacer * .5;
//         padding-left: $spacer;
//         padding-right: $spacer;

//         border-bottom: solid 1px $gray-300;
//         font-size: $font-size-base * 1;
//         position: relative;

//         .icon-wrapper {
//             padding: 5px;
//             position: absolute;
//             top: 0;
//             right: 0;
//             cursor: pointer;

//             &:nth-child(2) {
//                 right: 20px;
//             }
//         }

//         .subject-title {
//             width: 50px;
//             display: inline-block;
//             font-size: $font-size-base * 1;
//         }

//         input {
//             font-size: $font-size-base * 1;
//             font-weight: bold;
//             width: calc(100% - 70px);
//             display: inline-block;
//             background: none;
//             border: none;
//             box-shadow: none;
//             color: $gray-700;
//         }

//     }

//     .to-container {

//         padding-top: 10px;
//         padding-bottom: 0;
//         padding-left: $spacer;
//         padding-right: $spacer;
//         border-bottom: solid 1px $gray-300;

//         // .form-group, .object-search-input, input, span {
//         //     display: inline-block;
//         //     width: auto;
//         //     margin-bottom: 0;
//         // }

//         // .form-group, .object-search-input {
//         //     min-width: 300px;
//         // }

//         // input {
//         //     background: transparent;
//         //     border: none;
//         //     box-shadow: none;
//         // }

//         .to-wrapper span {
//             font-size: $font-size-base * 0.8;
//             margin-right: 5px;
//             border: solid 1px $green;
//             border-radius: 20px;
//             padding: 2px 10px;
//             white-space: pre;
//             display: inline-block;
//             margin-bottom: $spacer;


//             i {
//                 color: $red;
//                 position: relative;
//                 top: 1px;
//                 margin-left: 5px;
//                 cursor: pointer;
//             }

//         }

//         &.editing .to-wrapper span {
//             border: solid 1px $gray-400;
//             display: inline-block;
//             margin-bottom: $spacer;

//         }

//         // label {
//         //     display: none;
//         // }

      

//         // &.for-contact {

//         //     padding: 8px 12px;
//         //     border-bottom: solid 1px $gray-400;

//         //     span {
//         //         border: solid 1px $warning;
//         //     }
//         // }

//     }
// }

// .archk-send-text {

//     #archk-send-text-body-input {
//         padding: 10px 12px;
//         border: none;
//         border-top: solid 1px $gray-400;
//         font-size: $font-size-base * 0.9;
//         box-shadow: none;
//         background: transparent;
//         min-height: 150px;
//     }

//     .to-container {

//         padding: 10px 12px;
//         border: solid 1px $gray-300;
//         border-top: none;
//         border-bottom: none;

//         .form-group, .object-search-input, input, span {
//             display: inline-block;
//             width: auto;
//             margin-bottom: 0;
//         }

//         .form-group, .object-search-input {
//             min-width: 300px;
//         }

//         input {
//             background: transparent;
//             border: none;
//             box-shadow: none;
//         }

//         span {
//             font-size: $font-size-base * 0.8;
//             margin-right: 5px;
//             border: solid 1px $green;
//             border-radius: 20px;
//             padding: 2px 10px;

//             i {
//                 color: $red;
//                 position: relative;
//                 top: 1px;
//                 margin-left: 5px;
//                 cursor: pointer;
//             }

//         }

//         label {
//             display: none;
//         }

//         &.editing span {
//             border: solid 1px $gray-400;
//         }

//     }

// }


.archk-send-email-modal {

    .modal-content {
        box-shadow: none;
        background: white;
    }

    .modal-header {
        background: white;
        border-bottom: none;

        button {
            position: relative;
            left: 4px;
        }
    }

    .card {
        box-shadow: none;
    }

    .archk-send-text.card {
        margin-bottom: 0;
    }

}

.archk-send-email {

    .react-select {

        font-size: $font-size-base * 1;

        div {
            color: $gray-700 !important;
        }

    }

    #toolbar {
        // margin-top: $spacer * 2;
    }

    .show-attachments {

        // margin-top: $spacer;
        // margin-bottom: $spacer * 2;
        background-color: $gray-100;
        // padding: $spacer;

        padding: 10px 12px;

        .show-attachments-container {
            display: flex;
            flex-wrap: wrap;
        }


        .attachment {
            background-color: $white;
            padding: 3px 14px;
            margin-bottom: 5px;
            position: relative;
            border-radius: 20px;
            padding-right: 30px;
            margin-right: $spacer;

            float: left;

            .remove {

                position: absolute;
                right: 10px;
                color: $red;
                cursor: pointer;
                margin-top: 1px;

            }

        }

    }

    #toolbar {
        border-radius: 0;
    }

    .ql-toolbar {

        .ql-picker, button, select {
            position: relative;
            top: 3px;
        }

        .ql-background {
            top: 1px;
        }

        button.send {
            background: $green;
            height: auto;
            color: white;
            padding: 5px 30px;
            width: auto;
            top: 0;
            margin-left: 8px;

            &:focus {
                color: $white !important;
            }
            &:hover {
                color: $white !important;
            }

        }

        button.btn.btn-outline-success:focus {
            color: $green !important;
        }

        button.template {
            background: white;
            color: $green;
            border: solid 1px $green;
            height: auto;

            padding: 5px 30px;
            width: auto;
            top: 0;
            margin-left: 8px;

            &:focus {
                color: $white !important;
            }

        }

    }

    .quill {
        .ql-container {
            .ql-editor {
                background: $white;
                padding-left: $spacer;
                // padding-right: $spacer;
                // padding-top: $spacer * .75;
                // padding-bottom: $spacer * .75;
                padding: 10px 12px;

                transition: all .3s;
                height: auto;
                color: red;
    
                p {
                    color: $input-color !important;
                    margin-bottom: 0;
                }
            
                &:focus {
                    color: $gray-900;
                    background: $white;
                    border: solid 1px $gray-300 !important;
                    font-weight: bold;
                    border-radius: 0;
            
                    &::placeholder {
                        color: lighten($gray-900, 20%);
                    }
                }
            }
        }
       
    }
    

    .archk-send-email-subject {

       
        padding: 2px 12px;
        border: solid 1px $gray-300;
        border-bottom: none;
        font-size: $font-size-base * 1;

        .subject-title {
            width: 70px;
            display: inline-block;
            font-size: $font-size-base * 1;
        }

        .form-control {
            font-size: $font-size-base * 1;
            font-weight: bold;
            width: calc(100% - 70px);
            display: inline-block;
            background: none;
            border: none;
            box-shadow: none;

        }
    }

   
    .to-container {

        border: solid 1px $gray-300;
        // border-top: none;
        border-bottom: none;

        padding: 10px 12px;


        &.editing {
            padding: 0;

        }

        .section {
            padding: 10px 12px;
            border-bottom: solid 1px $gray-300;

            &.border-bottom-none {
                border-bottom: none;
            }

        }

        .form-group, .object-search-input, input, span {
            display: inline-block;
            width: auto;
            margin-bottom: 0;
        }

        .form-group, .object-search-input {
            min-width: 300px;
        }

        input {
            background: transparent;
            border: none;
            box-shadow: none;
        }

        .span {
            font-size: $font-size-base * 0.8;
            margin-right: 5px;
            border: solid 1px $green;
            border-radius: 20px;
            padding: 2px 10px;

            i {
                color: $red;
                position: relative;
                top: 1px;
                margin-left: 5px;
                cursor: pointer;
            }

        }

        label {
            display: none;
        }

        &.editing .span {
            border: solid 1px $gray-400;
        }

        &.for-contact {

            padding: 8px 12px;
            border-bottom: solid 1px $gray-400;

            span {
                border: solid 1px $warning;
            }
        }

    }
}
