.lds-ring {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid $cyan;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $cyan transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

//   $blue:    #053A53 !default;
// $cyan:  #8965e0 !default;
// $red:     #F5496C !default;
// $orange:  #fb6340 !default;
// $yellow:  #EDCB31 !default;
// $green:   #2dce89 !default;
// $teal:    #2CC4E4 !default;
// $cyan:    #2bffc6 !default;

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
      border-color: $cyan transparent transparent transparent;
    }

    25% {
      border-color: $cyan transparent transparent transparent;
    }
   
    50% {
      border-color: $teal transparent transparent transparent;
    }

    75% {
      border-color: $cyan transparent transparent transparent;
    }
    87% {
      border-color: $teal transparent transparent transparent;
    }
    100% {
      transform: rotate(360deg);
      border-color: $cyan transparent transparent transparent;
    }
  }
 
  .lds-ring-sm {
    width: 20px;
    height: 20px;
  }
  .lds-ring-sm div {
    width: 16px;
    height: 16px;
    margin: 2px;
    border: 2px solid #ddd;
  }