.archk-queue-controller {


    @keyframes pulse-red {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(52, 152, 219, 0.7);
        }
        
        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(52, 152, 219, 0);
        }
        
        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(52, 152, 219, 0);
        }
    }




    .archk-queue-controller-container {
        position: fixed;
        z-index: 100;
        top: 50%; left: 50%; transform: translate(-50%, -50%);
        background: $white; 

        width: 250px;
        text-align: center;

        .archk-queue-controller-pulse {
            // position: absolute;
            animation: pulse-red 2s infinite;
            box-shadow: 0 0 0 0 rgba(52, 152, 219, 1);
            border-radius: 16px;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
        
     
        .archk-queue-controller-wrapper {
            // position: absolute;
            // top: 0;
            // right: 0;
            // left: 0;
            // bottom: 0;
            z-index: 2;
            position: relative;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }

     

        .archk-queue-controller-header {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }

        .archk-queue-controller-body {
            border-bottom: solid 1px $gray-200;
            padding-top: $spacer;
            padding-bottom: $spacer;
            // background: $gray-100;
        }
        
        .archk-queue-controller-info {
            padding-top: $spacer;
            padding-bottom: $spacer;
            background: $gray-100;
        }

        .archk-queue-controller-footer {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }

    }


}