.nav-pills {

     .nav-link {
        border-radius: $border-radius;
        background: $white;
        border: solid 2px $orange;
        // color: $white;
        color: $orange;
        box-shadow: none;
        padding: .2rem;
        transition: all .2s;

        &:hover {
            background: lighten($orange, 20%);
            color: $white;
            cursor: pointer;
        }

        &.active {
            background: $warning !important;
            color: $white !important;

        }
    }
}


#desktop-navbar-top {

    .navbar-portal-link {
        font-size: 14px;
        border-radius: $border-radius;
        padding: 6px 16px;
        background: $white;
        border: dashed 1px $gray-600;
        font-weight: bold;
        transition: all .2s;

        &:hover {
            background: $gray-700;
            color: $white !important;
        }
        // color: white;
    }

    .notification {
        background: $white;
        color: $white;
        border-radius: 30px;
        display: inline-block;
        padding-left: 8px;
        padding-right: 8px;
        border: solid 1px $red;
        color: $red;
        font-size: .7rem;
        position: relative;
        top: -2px;

        &.notification-info {
            border: solid 1px $info;
            color: $white;
            background: lighten( $info, 20%);
        }
        &.notification-warning {
            border: solid 1px $warning;
            color: $white;
            background: lighten( $warning, 20%);
        }
        &.notification-danger {
            border: solid 1px $danger;
            color: $white;
            background: lighten( $danger, 20%);
        }
        &.notification-success {
            border: solid 1px $success;
            color: $white;
            background: lighten( $success, 20%);
        }
    }

   

    .header-right {

        .dropdown-content {
            button {
                border-radius: 0 !important;
            }
        }

        button {
            border-radius: 20px;
            padding-top: 4px;
            padding-bottom: 2px;
            position: relative;
            top: -2px;
            i {
                font-size: $font-size-base !important;
            }
        }


        ul {
            li {
                i {
                    padding-left: 15px;
                    font-size: 1rem;

                    &.navbar-block {
                        background: $gray-100;
                        position: relative;
                        padding: 5px;
                        margin-left: 10px;
                        width: 34px;
                        font-size: .8rem;
                        display: inline-block;
                        border-radius: 6px;
                        border: solid 1px $gray-400;
                    }
                }
                text-align: center;
            }
        }
        

    }

    .search-wrapper {
        &:hover {
            .search-icon {
              color: $info !important;
            //   transform: rotate(90deg);
            }
        }
    }

    .search-icon {
        position: absolute;
        top: 7px;
        left: 12px;
        font-size: 12px;
    }

    input {
        display: block;
        width: 100%;
        border-radius: 20px;
        padding-left: 30px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    ul {
        display: inline-block;
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;

        li {
            list-style: none;
            display: inline-block;
            padding-top: 4px;
            padding-bottom: 2px;
            position: relative;
        }
    }

    ul.ul-standard {
        position: relative;
        top: -10px;
        
        li {
            margin-left: 20px;
        }

    }

    ul.navbar-icons {
     

        li {
            list-style: none;
            display: inline-block;

            &:nth-child(2) {
                .circle-wrapper {
                    background: $orange !important;

                    .circle-wrapper-marker {
                        background: darken($orange, 20%);
                    }
                }
            }
            &:nth-child(3) {
                .circle-wrapper {
                    background: $purple !important;

                    .circle-wrapper-marker {
                        background: darken($purple, 20%);
                    }
                }
            }


            .circle-wrapper {
                width: 32px;
                height: 32px;
                background: $teal;
                color: $white;
                text-align: center;
                display: inline-block;
                border-radius: 100%;
                padding-top: 5px;
                font-size: 16px;
                position: relative;
                margin-left: 20px;
                border: solid 1px #ddd;

                i {
                    position: absolute;
                    top: calc(50% - 8px);
                    left: calc(50% - 8px);
                    // z-index: 2;
                }

                .circle-wrapper-marker {
                    position: absolute;
                    top: -12px;
                    right: -12px;
                    color: black;
                    background: darken($teal, 20%);
                    border-radius: 100%;
                    width: 26px;
                    text-align: center;
                    border: dotted 1px white;
                    color: $white;
                    font-size: 12px;
                    padding-top: 3px;
                    padding-bottom: 3px;
                }

            }
        }
    }

    .header-bottom {

        .header-right-side {
            padding-right: $spacer;
            ul {
                li {
                    padding-right: 0    
                }
            }
        }
        ul {
            padding-top: $spacer * .5;
            padding-bottom: $spacer * .5;
            li {
                padding-left: $spacer * 1.25;
                padding-right: $spacer * 1.25;

                &:first-child {
                    padding-left: 0;
                }

                &.active {
                    background: $info;
                    color: $white;
                    padding-left: $spacer * 1.25;

                
                    .text-danger {
                        color: $white !important;
                    }

                    a {
                        color: $white;

                        &.dropdown-item  {
                            color: $body-color !important; 

                        }
                    }
                    border-radius: 20px;
                }
            }
        }
    }

} 

@include media-breakpoint-down(sm) {
    .dash-header-open {

        .btn {
            display: inline-block !important;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            margin-top: $spacer * 1.5;
        }

    }

    .g-sidenav-pinned .sidenav {
        max-width: 300px !important;
    }
    
}

.archk-navigation {

    .archk-navigation-top {
        padding-top: $spacer;
        padding-bottom: $spacer;
    }


    &.success {
        ul {
            li {

                &:hover {
                    border-bottom: solid 3px $warning;
                }

                &.active {
                    border-bottom: solid 3px $success;

                    i {
                        color: $success;
                    }
                }
            }
        }
    }

    

    ul {
        li {
            padding-bottom: $spacer * .5;
            margin-right: $spacer * 3;
            color: $gray-700;
            font-size: $font-size-sm;
            text-transform: uppercase;
            position: relative;
            top: 0;
            transition: all .2s;

            &.is-dropdown {

                margin-left: -4px;

                &:hover {
                    border-bottom: none;
                    left: 0;
                }
            }

            &:hover {
                cursor: pointer;
                border-bottom: solid 3px $success;
                color: $gray-900;
                left: 2px;
            }

            &.active {
                border-bottom: solid 3px $primary;
                color: $gray-900;
                left: 0 !important;
                font-weight: bold;

                i {
                    color: $info;
                }
            }
        }
    }

}