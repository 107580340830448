.noScroll {
    /* ...or body.dialogShowing */
    overflow: hidden !important;
}

.cursor-pointer {
    cursor: pointer;
}

.progress-gradient.progress {

    &.finished {
        .progress-bar {
            background: $green !important
        }
    }

    .progress-bar {
        background: linear-gradient(to right, $red 5%, $orange 20%, $green 60%) !important;
    }

}

.background.full-background {
    overflow: scroll;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    background: $body-bg;
    padding: $spacer * 3;
}

.list-style-none {
    list-style: none;
}

ul.hover-click {
    li {
        &:hover {
            background: $gray-200;
            cursor: pointer;
        }
    }
}

.dz-message {
    z-index: 30;
}

.border-warning {
    border: solid 1px $orange;
}
.border-danger {
    border: solid 1px $red;
}
.border-success {
    border: solid 1px $green;
}
.border-info {
    border: solid 1px $teal;
}

.border-radius-50 {
    border-radius: 50px;
}

.container-half {
    width: 500px;
}

.card .archk-api-error, .modal .archk-api-error {
    border-radius: 0 !important;

    background: $orange !important;
    color: white;

    .text-info,.text-warning,.text-danger, .text-dark {
        color: $white !important;
    }
}

.rbc-calendar {
    .rbc-toolbar {
        padding-left: 0;
        padding-right: 0;

        .rbc-btn-group:nth-child(3) {
            text-align: right;
        }

        button {
            font-size: $font-size-sm;
        }
    
    }

    .rbc-event {
        background-color: $info;
        border: none;
        border-radius: 2px;
        border: solid 1px lighten($info, 20%);
    }

    .rbc-event-label {
        font-size: $font-size-sm;
    }

    .rbc-event-content {
        font-size: $font-size-base;
    }

    .rbc-label, .rbc-toolbar-label {
        font-size: $font-size-base;

    }

    .rbc-current-time-indicator {
        background-color: $warning;
        height: 3px;
        opacity: .4;
    }

    .rbc-time-content > .rbc-day-slot {
        background-color: $white;
    }

    .rbc-today {
        background-color: lighten($orange, 30%);
    }

    .rbc-off-range-bg {
        opacity: .20;
        // background-color: $gray-100;
        background: repeating-linear-gradient(
            90deg,
            $gray-900,
            $gray-900 10px,
            lighten($gray-900, 15) 10px,
            lighten($gray-900, 15) 20px
          );
    }

    .rbc-agenda-content {
        tr {
            background: $white !important;
        }
    }
    
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td  {
        border-left: 1px solid #ddd;
    }
    
    
    .rbc-agenda-content {
        tr.rbc-agenda-colored {
            color: $body-color !important;
        }
    }
    

}


.archk-calendar-loading  .rbc-time-view, .archk-calendar-loading .rbc-month-view {
    opacity: 0.3;
}
  